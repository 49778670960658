<template>
  <!-- ICONS -->

  <h2>Icons</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/components/_icons.scss</code></em>
  </p>

  <h3 class="styleguide-h3">Icon set</h3>

  <div class="styleguide-icons styleguide-icons--icon-set">
    <div
      v-for="(icon, index) in icons"
      :key="index"
    >
      <SvgIcon :name="icon"/><span>{{ icon }}</span></div>
  </div>

  <h3 class="styleguide-h3">Icon sizing</h3>

  <h4>Size: extra-extra-large (40px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="xxl"/></div>
  </div>

  <h4>Size: extra-large (32px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="xl"/></div>
  </div>

  <h4>Size: large (28px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="lg"/></div>
  </div>

  <h4>Size: default (24px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle"/></div>
  </div>

  <h4>Size: small (20px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="sm"/></div>
  </div>

  <h4>Size: extra-small (16px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="xs"/></div>
  </div>

  <h4>Size: extra-extra-small (12px)</h4>

  <div class="styleguide-icons styleguide-icons--dark">
    <div><SvgIcon name="exclamation-triangle" size="xxs"/></div>
  </div>

  <h3 class="styleguide-h3">Inline icons</h3>

  <p class="font-size-biggest">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="sm"/>&nbsp;An inline icon</a>
    inventore magni reiciendis voluptatum
    <a href="#">Another icon&nbsp;<SvgIcon name="exclamation-triangle" size="sm"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

  <p class="font-size-bigger">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="sm"/>&nbsp;An inline icon</a>
    inventore magni reiciendis voluptatum
    <a href="#">Inline icon&nbsp;<SvgIcon name="exclamation-triangle" size="sm"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

  <p class="font-size-big">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="xs"/>&nbsp;An inline icon</a>
    inventore magni reiciendis voluptatum
    <a href="#">Another icon&nbsp;<SvgIcon name="exclamation-triangle" size="xs"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="xs"/>&nbsp;ICONIC</a>
    inventore magni reiciendis voluptatum
    <a href="#">Inline icon&nbsp;
      <SvgIcon name="exclamation-triangle" size="xs"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

  <p class="font-size-small">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="xxs"/>&nbsp;An inline icon</a>
    inventore magni reiciendis voluptatum
    <a href="#">Another inline&nbsp;<SvgIcon name="exclamation-triangle" size="xxs"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

  <p class="font-size-smaller">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut possimus
    pariatur incidunt labore est obcaecati dolores, temporibus
    <a href="#"><SvgIcon name="exclamation-triangle" size="xxs"/>&nbsp;An inline icon</a>
    inventore magni reiciendis voluptatum
    <a href="#">Inline icon&nbsp;<SvgIcon name="exclamation-triangle" size="xxs"/></a>
    et nisi dicta ex autem! Accusamus ducimus iusto nobis.
  </p>

</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'StyleguideIconsView',

  components: {
    SvgIcon,
  },

  data() {
    return {
      icons: [],
    }
  },

  mounted() {
    this.getIcons()
  },

  methods: {
    getIcons() {
      const icons = require.context('@/assets/icons', true, /^.*\.svg$/)

      icons.keys().forEach((icon) => {
        const lastIndex = icon.lastIndexOf('/')

        this.icons.push(icon.substring(lastIndex + 1).split('.')[0])
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* STYLEGUIDE - ICONS */

.styleguide-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -$gutter-quarter;
  margin-bottom: ($gutter - $gutter-quarter);

  > div {
    display: block;
    margin: $gutter-quarter;
    padding: 10px; // 10 + 24 + 10 = 44px
    line-height: 1;
    transition: all 0.25s;
    background-color: white;
    color: $color-gray-darker;
  }

  &.styleguide-icons--icon-set {
    > div {
      flex-basis: $gutter * 4;
      padding: $gutter-half;
      border: 1px solid $color-gray-lightest;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        // Not working on a div, WTF
        border-color: $color-primary;
        background-color: $color-primary;
        color: white;
        @include shadow(2);
        cursor: pointer;
      }

      > span {
        display: block;
        margin-top: $gutter-half;
        font-size: $font-size-smaller;
      }
    }
  }

  &.styleguide-icons--dark {
    > div {
      background-color: $color-gray-darker;
      color: white;
    }
  }
}
</style>
